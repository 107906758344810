<template>
    <div class="initialize-login">
        <tip-spinner />
        <div v-if="shouldShowUnexpectedError" class="initialize-login__error">
            {{ unexpectedError }}
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import langConsts from '@/lang/en';
import loginConsts from '@/consts/loginConsts';
import router from '@/router';

export default {
    name: 'InitializeLogin',
    props: {
        didLoginAttemptFail: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState('login', ['isAppLoginFlowSsoOnly', 'ssoLoginUrl', 'identityProviderType']),

        loginTitle() {
            return langConsts.titleLogin;
        },
        unexpectedError() {
            return langConsts.unexpectedError;
        },
        shouldShowUnexpectedError() {
            return this.ssoLoginUrl === undefined || this.didLoginAttemptFail;
        }
    },
    async created() {
        const initializeLoginProcessRequest = { oidcReturnUrl: this.$route.query.oidcReturnUrl };

        await this.initializeLoginProcess(initializeLoginProcessRequest);

        if (this.isAppLoginFlowSsoOnly) {
            router.replace(`${loginConsts.urls.baseInternalLoginAppUrl}/${loginConsts.urls.urlPathLoginAuthenticate}`);
        } else {
            router.replace(`${loginConsts.urls.baseSubscriberAppUrl}/${loginConsts.urls.urlPathLoginUserFlowEmail}`);
        }
    },
    methods: {
        ...mapActions('login', ['initializeLoginProcess'])
    }
};
</script>

<style lang="scss">
.initialize-login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;

    &__error {
        color: $outrageous-orange;
        margin-top: 1rem;
    }
}
</style>
